@import-normalize;

* {
	margin: 0px;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Roboto', sans-serif;
}

h2 {
	font-size: 2em;
}

a {
	text-decoration: none;
}

ul {
	list-style-type: none;
}

.page-container {
	margin: 3%;
}

/* -----------navbar--------- */

.navbar {
	background-color: black;
	color: rgb(253, 217, 0);
	height: 80px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: sticky;
	top: 0;
	padding: 0 3%;
	z-index: 10;
}

.logo {
	height: 2rem;
}

@media screen and (max-width: 800px) {
	.logo {
		height: 1.2rem;
	}
}

.nav-menu {
	background-color: black;
	width: 20vw;
	height: 50vh;
	display: flex;
	justify-content: space-around;
	align-content: space-around;
	position: fixed;
	top: 80px;
	right: -100%;
	transition: 850ms;
}

.nav-menu.active {
	right: 0;
	transition: 350ms;
}

.nav-menu-items {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

a {
	color: rgb(253, 217, 0);
	text-decoration: none;
}

.nav-item {
	display: flex;
	height: 20%;
	width: 20vw;
}

.nav-item a {
	width: 100%;
	text-align: center;
}

.nav-item a:hover {
	color: white;
}

/* -----------Home content------------- */

.header {
	background-image: url(./assets/images/earth2.jpeg);
	width: 100%;
	height: 90vh;
	background-position: top;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 3%;
	color: whitesmoke;
	font-size: 1.5rem;
}
.header h1 {
	margin-bottom: 2px;
}

.homeContent {
	margin: 5rem 0;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 2rem;
}

.points {
	display: flex;
	margin-top: 5px;
	font-size: 1rem;
}

.card {
	display: flex;
	flex-direction: column;
	height: 30vh;
}

.cardTitle {
	text-align: center;
	padding: 20px;
	color: white;
}

.card0 {
	background-color: rgb(253, 217, 0);
}
.card1 {
	background-color: rgb(89, 89, 88);
}
.card2 {
	background-color: black;
}

.cardImage {
	width: 40%;
	height: 60%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	align-self: center;
}

.image0 {
	background-image: url(./assets/images/Picture1.png);
}

.image1 {
	background-image: url(./assets/images/Picture2.png);
}

.image2 {
	background-image: url(./assets/images/Picture3.png);
}

.home-picture {
	width: 75%;
	height: 75%;
	background-image: url(./assets/images/about.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	margin-left: auto;
	margin-right: auto;
	margin-top: 2rem;
}

.home-title {
	width: 60%;
	text-align: center;
}

.home-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	background-color: black;
}

.section-container {
	width: 100%;
	height: 600px;
	margin-top: 20px;
	margin-bottom: 20px;
	padding-top: 50px;
	padding-bottom: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: white;
}

.section-container h2 {
	font-family: 'Encode Sans Condensed', sans-serif;
	font-size: 3rem;
}

.section-container h3 {
	font-size: 2rem;
}

.section-container p {
	font-size: 1.5rem;
}

.section-solution-title {
	display: flex;
	flex-direction: column;
	gap: 20px;
	text-align: center;
	width: 80%;
	margin-bottom: 20px;
}

.section-company {
	width: 80%;
	height: 90%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

.description-container {
	width: 80%;
	height: 90%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

.description-item {
	display: flex;
	justify-content: flex-start;
	gap: 20px;
	align-items: center;
	font-size: 1.4rem;
}

.aboutSection {
	width: 70%;
	background-image: url(./assets/images/about.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}
.flowSection {
	background-image: url(./assets/images/Flow-Background.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.airysSection {
	background-image: url(./assets/images/Airys-Background.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.badgerSection {
	background-image: url(./assets/images/Badger-Background.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.companyColor {
	color: rgb(253, 217, 0);
}

/* ipad */
@media only screen and (min-width: 900px) and (max-width: 1500px) {
	h2 {
		font-size: 1.5rem;
	}
	.points {
		margin-top: 5px;
	}

	.points > p {
		font-size: 0.95rem;
	}
	.cardTitle {
		padding: 10px;
	}

	.header h1 {
		font-size: 2rem;
	}
	.header p {
		font-size: 1.4rem;
	}
}

@media only screen and (min-width: 1550px) {
	.header h1 {
		font-size: 4rem;
	}
	.header p {
		font-size: 2rem;
	}
}

/* mobile */
@media only screen and (min-width: 450px) and (max-width: 900px) {
	h2 {
		font-size: 1.5rem;
	}
	.points {
		margin-top: 5px;
	}

	.points > p {
		font-size: 0.8rem;
	}
	.cardTitle {
		padding: 10px;
	}

	.header h1 {
		font-size: 2rem;
	}
	.header p {
		font-size: 1.4rem;
	}
}

@media only screen and (min-width: 550px) and (max-width: 840px) and (orientation: landscape) {
	h2 {
		font-size: 1.1rem;
	}

	.homeContent {
		margin: 5rem 0;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 1rem;
	}

	.points {
		margin-top: 5px;
	}

	.points > p {
		font-size: 0.75rem;
	}
	.cardTitle {
		padding: 10px;
		font-size: 0.75rem;
	}

	.card {
		height: 50vh;
	}

	.cardImage {
		width: 30%;
		height: 50%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		align-self: center;
	}

	.header h1 {
		font-size: 1.5rem;
	}
	.header p {
		font-size: 1.4rem;
	}
	.section-container {
		width: 100%;
	}

	.home-title {
		width: 100%;
	}
	.section-container {
		height: 400px;
		margin-top: 15px;
		margin-bottom: 15px;
		padding-top: 15px;
		padding-bottom: 15px;
	}
	.section-solution-title {
		gap: 20px;
		text-align: center;
		width: 90%;
	}
	.section-container h2 {
		font-size: 1.9rem;
	}

	.section-container h3 {
		font-size: 1.4rem;
	}

	.section-container p {
		font-size: 1.1rem;
	}
	.description-container {
		width: 90%;
		height: 100%;
	}

	.description-item {
		font-size: 1rem;
	}
}

@media only screen and (max-width: 450px) {
	.homeContent {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 5rem;
	}
	h2 {
		font-size: 1.5rem;
	}
	.points {
		margin-top: 5px;
	}

	.points > p {
		font-size: 0.8rem;
	}
	.cardTitle {
		padding: 10px;
	}

	.header {
		background-size: 250%;
	}

	.header h1 {
		font-size: 1.5rem;
	}
	.header p {
		font-size: 1.4rem;
	}

	.home-title {
		width: 100%;
	}
	.section-container {
		height: 400px;
		margin-top: 15px;
		margin-bottom: 15px;
		padding-top: 15px;
		padding-bottom: 15px;
	}
	.section-solution-title {
		gap: 20px;
		text-align: center;
		width: 90%;
	}
	.section-container h2 {
		font-size: 1.5rem;
	}

	.section-container h3 {
		font-size: 1rem;
	}

	.section-container p {
		font-size: 1rem;
	}
	.description-container {
		width: 90%;
		height: 100%;
	}

	.description-item {
		font-size: 0.9rem;
	}
	.section-company {
		width: 90%;
	}
	.aboutSection {
		width: 100%;
	}
}

/* -----------Solutions content------------- */

.solutions-page {
	color: white;
	background-color: black;
	width: 100%;
	height: 100%;
	padding: 5%;
	padding-top: 3%;
}

.description {
	height: 70vh;
	display: grid;
	grid-template-columns: 2fr 1fr;
	grid-auto-flow: column;
	grid-gap: 5rem;
	color: white;
	margin-top: 2rem;
}

.separator {
	width: 100%;
	height: 2px;
	margin: 5rem 0;
	background-color: rgb(253, 217, 0);
}

.solution-left {
	font-size: 1.3rem;
}

.solution-titles {
	margin-bottom: 2rem;
}

.solution-title {
	color: rgb(253, 217, 0);
	font-size: 2.5rem;
	font-family: 'Stardos Stencil', sans-serif;
}

.solution-subtitle {
	color: rgb(253, 217, 0);
}

.solution-image {
	width: 90%;
	margin-left: auto;
	margin-right: auto;
}

.airys {
	width: 50%;
	align-self: center;
}

.video-responsive {
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	height: 0;
}

.video-responsive iframe {
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	position: absolute;
}

.info-buttons {
	display: flex;
	justify-content: space-evenly;
	margin-top: 5rem;
}

.specs {
	color: white;
	background-color: black;
	padding: 5px 10px;
	border-color: white;
	font-size: 1.2rem;
	cursor: pointer;
}

.modalBackground {
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: rgba(200, 200, 200, 0.6);
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
}

.popup {
	position: absolute;
	top: 15%;
	left: 15%;
	width: 70vw;
	height: 70vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.popup-inner {
	position: relative;
	padding: 32px;
	width: 100%;
	background-color: #fff;
}

.popup-inner .close-btn {
	position: absolute;
	top: 0px;
	right: 0px;
	width: 40px;
	height: 40px;
	color: black;
	display: flex;
	align-items: flex-end;
	justify-content: right;
}

.popup-inner .close-btn:hover {
	cursor: pointer;
}

@media only screen and (max-width: 900px) {
	.solutions {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	.solutions-container {
		display: flex;
		flex-direction: column;
		gap: 4rem;
	}

	.solution-container {
		height: 100vh;
		margin-bottom: 3rem;
	}

	.description {
		display: flex;
		flex-direction: column;
		margin-top: 1rem;
	}

	.solution-left {
		font-size: 1rem;
	}

	.solution-title {
		color: rgb(253, 217, 0);
		font-size: 1.5rem;
	}

	.solution-image {
		width: 80%;
		margin-top: -3rem;
		margin-left: auto;
		margin-right: auto;
	}

	.airys {
		width: 50%;
	}

	.info-buttons {
		margin-top: 3em;
		margin-bottom: 1rem;
	}

	.separator {
		margin-bottom: 2rem;
	}

	.popup {
		top: 5%;
		left: 5%;
		width: 90vw;
	}

	.popup-inner {
		position: relative;
		padding: 32px 20px;
		padding-bottom: 20px;
		width: 100%;
		background-color: #fff;
	}
}
@media only screen and (min-width: 550px) and (max-width: 840px) and (orientation: landscape) {
	.solutions {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	.solutions-container {
		display: flex;
		flex-direction: column;
		gap: 4rem;
	}

	.solution-container {
		margin-bottom: 12rem;
	}

	.description {
		display: flex;
		flex-direction: column;
		margin-top: 1rem;
	}

	.solution-left {
		font-size: 1rem;
	}

	.solution-title {
		color: rgb(253, 217, 0);
		font-size: 1.5rem;
	}

	.solution-image {
		width: 50%;
		margin-top: -3rem;
		margin-left: auto;
		margin-right: auto;
	}

	.airys {
		width: 25%;
	}

	.info-buttons {
		margin-top: 3em;
		margin-bottom: 1rem;
	}

	.separator {
		margin-bottom: 2rem;
	}

	.popup {
		top: 5%;
		left: 5%;
		width: 90vw;
	}

	.popup-inner {
		position: relative;
		padding: 32px 20px;
		padding-bottom: 20px;
		width: 100%;
		background-color: #fff;
	}
}

/* -----------About content------------- */

.about {
	height: 90vh;
	display: flex;
	flex-direction: column;
}

.about-picture {
	width: 75%;
	height: 75%;
	background-image: url(./assets/images/about2.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	margin-left: auto;
	margin-right: auto;
	margin-top: 2rem;
}

.about-content {
	margin: 5rem 10rem;
}

.about-content p {
	font-size: 1.5rem;
}

@media only screen and (max-width: 900px) {
	.about {
		height: 80vh;
	}
	.about-picture {
		width: 100%;
		height: 50%;
	}

	.about-content {
		margin: 0 1rem;
	}

	.about-content p {
		font-size: 1rem;
	}
}

/* ------contact page------------- */

.left {
	width: 100%;
	max-width: 500px;
	margin-top: 3rem;
}
.contact {
	width: 100%;
	height: 80vh;
	background-image: url(./assets/images/wellhead.jpeg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 3rem;
}
.contact > h2 {
	color: white;
}

.contact-item {
	display: flex;
	align-items: center;
	border-radius: 8px;
	margin-bottom: 1rem;
	color: white;
	font-size: 1.1rem;
}
.icon {
	color: rgb(253, 217, 0);
	display: flex;
	align-items: center;
	justify-content: center;
}

/* --------footer--------------- */

.footer-container {
	background-color: #242424;
	padding: 4rem 0 2rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.footer-links {
	display: flex;
	gap: 100px;
}

.footer-link-items {
	display: flex;
	flex-direction: column;
	text-align: left;
}

.footer-link-items h3 {
	margin-bottom: 16px;
	color: #fff;
}

.footer-link-items a {
	color: #fff;
	text-decoration: none;
	margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
	color: rgb(253, 217, 0);
	transition: 0.3s ease-out;
}

.website-rights-wrap {
	margin-top: 40px;
}

.website-rights {
	margin-top: 40px;
	color: #fff;
}

.website-rights a {
	color: #fff;
}

@media screen and (max-width: 820px) {
	.footer-links {
		padding-top: 2rem;
	}
}
